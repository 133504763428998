import React from "react"
import apiRequest from "../../hooks/HttpRequest"
import { Link } from "gatsby"
import axios from "axios"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      PrimaryEmail: "",
      Password: "",
      errorMessage: "",
      toShowPasswordForm: false,
      Password1: "",
      Password2: "",
      toShowLoginButton: false
    }

    this.sendForgotPasswordEmail = this.sendForgotPasswordEmail.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  componentDidMount() {
    if (window.location.href.toString().indexOf("/")) {
      // console.log("Query string available");
      const formData = new FormData()
      formData.append("QueryString", window.location.href.toString())
      axios
        .post(
          apiRequest.baseURL + "/Members/CheckPasswordResetLinkExpiry/",
          formData,
          apiRequest.multipartFormHeader
        )
        .then((response) => {
          if (response.data.Status === "Success") {
            this.setState({
              toShowPasswordForm: true,
              PrimaryEmail: response.data.Email
            });
          }
          if (response.data.Status === "Error") {
            // console.log("link expiry");
            toast.error("Password reset Link Expired! Try again!!");
            this.setState({
              toShowPasswordForm: false
            });
          }
        })
        .catch((e) => {
          // console.log(e)
        })
    }
  }

  handleOnChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked ? true : false })
    } else {
      this.setState({ [e.target.name]: e.target.value, errorMessage: "" })
    }
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  sendForgotPasswordEmail = (event) => {
    event.preventDefault()
    const formData = new FormData()
    formData.append("Email", this.state.PrimaryEmail)
    formData.append("URL", window.location.href)

    axios
      .post(
        apiRequest.baseURL + "/Members/ForgotPassword/",
        formData,
        apiRequest.multipartFormHeader
      )
      .then((response) => {
        // console.log(response.data.Status)
        // console.log(response.data.Status === "Error")
        if (response.data.Status === "Success") {
          toast.success("Password reset mail Sent!");
          //saveUserOnSessionStorage(response.data.User)
        }
        if (response.data.Status === "Error") {
          toast.error("Invalid Email. Try using Valid Email!")
        }
      })
      .catch((e) => {
        // console.log(e)
      })
  }

  resetPassword = (event) => {
    event.preventDefault()

    if (this.state.Password1 !== this.state.Password2) {
      toast.error("Password doesn't match!")
      return
    }
    const formData = new FormData()
    formData.append("Email", this.state.PrimaryEmail)
    formData.append("Password1", this.state.Password1)
    formData.append("Password2", this.state.Password2)

    axios
      .post(
        apiRequest.baseURL + "/Members/ResetPassword/",
        formData,
        apiRequest.multipartFormHeader
      )
      .then((response) => {
        // console.log(response.data.Status)
        // console.log(response.data.Status === "Error")
        if (response.data.Status === "Success") {
          toast.success("Password has been reset. ");
          this.setState({
            toShowLoginButton: true,
            Password1: "",
            Password2: ""
          });
        }
        if (response.data.Status === "Error") {
          toast.error("Invalid Credentials! Check Email!!")
        }
      })
      .catch((e) => {
        // console.log(e)
      })
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/members/profile`)
    }
    return (
      <>
        <ToastContainer />
        <div className="page-title-area">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2>Forgot Password</h2>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Forgot Password</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="recover-area ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="contact-form-action">
                  <div className="form-heading text-center">
                    <h3 className="form-title">Reset Password!</h3>
                    <p className="reset-desc">
                      Enter the email of your account to reset the password.
                      Then you will receive a link to email to reset the
                      password. If you have any issue about reset password{" "}
                      <Link to="/contact">contact us.</Link>
                    </p>
                  </div>
                  <form method="post">
                    <div className={!this.state.toShowPasswordForm ? "row" : "d-none"}>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="email"
                            name="PrimaryEmail"
                            value={this.state.PrimaryEmail}
                            placeholder="Enter Primary / Secondary Email"
                            onChange={this.handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <Link className="log-in" to="/login">
                          Login
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <p className="now-register">
                          Not a member?
                          <Link to="/register">
                            Register
                          </Link>
                        </p>
                      </div>
                      <div className="col-12">
                        <button className="default-btn" type="submit" onClick={this.sendForgotPasswordEmail}>
                          Send Reset Password Link
                          <span />
                        </button>
                      </div>
                    </div>
                    <div className={this.state.toShowPasswordForm ? "row" : "d-none"}>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="password"
                            name="Password1"
                            value={this.state.Password1}
                            placeholder="Enter new password"
                            onChange={this.handleOnChange}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="password"
                            name="Password2"
                            value={this.state.Password2}
                            placeholder="Confirm password"
                            onChange={this.handleOnChange}
                          />
                        </div>
                        <div className="col-12">
                          <button className="default-btn" type="submit" onClick={this.resetPassword}>
                            Reset Password
                          <span />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
