import React from "react"
import Layout from "../components/layout"
import ForgotPage from "../components/members/resetPassword"

const Forgot = () => {
  return (
    <Layout>  
     <ForgotPage/>
    </Layout>
  )
}

export default Forgot
